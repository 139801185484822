import React from "react"

/* Import Local Styles */
import "../icon.css"

const RightPointerSvg = () => (
  <svg
    className="icon no-fill"
    viewBox="0 0 22 40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.0391 19.9536C16.9671 17.4328 14.428 15.6153 12.4329 14.4743C10.4379 13.3333 3.25109 9.4461 2.44627 9.18076C-1.35115 7.9602 0.405863 3.07794 0.405863 3.07794C0.405863 3.07794 2.68432 0 4.06726 0C4.47534 0.716418 4.9401 1.43284 5.4502 2.16252C5.9603 2.87894 6.44773 3.63516 6.91249 4.40464C9.06625 7.45605 11.4581 10.3615 14.0879 13.1211C16.7291 15.8806 19.3249 18.1493 21.8868 19.9536L20.0391 19.9536ZM21.8755 19.9536C19.3136 21.8109 16.7178 24.1194 14.0766 26.8789C11.4354 29.6385 9.04357 32.5439 6.90115 35.5954C6.43639 36.3118 5.94896 37.0547 5.43886 37.7977C4.92876 38.5539 4.05592 40 4.05592 40C4.05592 40 0.564558 36.8027 0.564558 35.2239C0.564558 33.6451 1.04065 31.4163 1.97017 30.6468C4.79273 29.1078 10.4379 26.6799 12.4442 25.539C14.4393 24.398 16.9785 22.5406 20.0504 19.9668L21.8755 19.9668L21.8755 19.9536Z"
      className="fill-with-text-color"
    />
  </svg>
)

export default RightPointerSvg
