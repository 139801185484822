import React from "react"
import classnames from "classnames"

/* Import Global Components */
import RightPointer from "~components/icon/normal/rightPointer"

/* Import Local Styles */
import "./next-slide-button.css"

const NextSlideButton = ({ active }) => {
  const goToNextSlide = () => {
    if (window && window.fullpage_api) {
      window.fullpage_api.moveSlideRight()
    }
  }

  return (
    <div className={classnames("next-slide-button", { active: active })}>
      <button onClick={goToNextSlide}>
        <RightPointer />
      </button>
    </div>
  )
}

export default NextSlideButton
